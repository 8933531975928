
  
  .animate-fade-in-from-top {
    animation: fade-in-from-top 1s ease-in-out forwards;
  }
  
  .transition-opacity {
    transition: opacity 1s ease-in-out;
  }
  .text-white {
    color: white;
  }

  @keyframes fade-in-from-top {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }

  }


  @keyframes slide-down {
    from {
      opacity: 1;
      transform: translateY(0px);
    }
    to {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
  
  @keyframes slide-up {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  
  .dropdown-enter {
    animation: slide-down 0.9s ease-out  forwards;
  }
  
  .dropdown-exit {
    animation: slide-up 0.3s ease-in forwards;
  }
  
  
  
 