@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'tailwindcss/tailwind.css';

body {
  @apply bg-black  min-h-screen;
}
::-webkit-scrollbar {
    display: none !important;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    text-decoration: none;
    color: unset;
  }
  h2 {
    font-family: 'Oswald';
  }
  ::-webkit-scrollbar {
    display: none !important;
  }
  
  [data-aos="new-animation"] {
    opacity: 0;
    transition-property: transform, opacity;
  
    &.aos-animate {
      opacity: 1;
    }
  
    @media screen and (min-width: 768px) {
      transform: translateY(10px);
  
      &.aos-animate {
        transform: translateX(0);
      }
    }
  }
  [data-aos="new-animate"] {
    opacity: 0;
    transition-property: transform, opacity;
  
    &.aos-animate {
      opacity: 1;
    }
  
    @media screen and (min-width: 768px) {
      transform: translateX(10px);
  
      &.aos-animate {
        transform: translateX(0);
      }
    }
  }

    [data-aos="new-animatedown"] {
    opacity: 0;
    transition-property: transform, opacity;
  
    &.aos-animate {
      opacity: 1;
    }
  
    @media screen and (min-width: 768px) {
      transform: translateY(-20px);
  
      &.aos-animate {
        transform: translateY(0);
      }
    }
  }


  @keyframes flip {
    0% {
      transform: scaleX(-1);
    }
    100% {
      transform: scaleX(1);
    }
  }
  
  @layer utilities {
    .animate-flip {
      animation: flip 0.9s forwards;
    }
    .delay-100 {
      animation-delay: 1.0s;
    }
    .delay-200 {
      animation-delay: 2.3s;
    }
    .delay-300 {
      animation-delay: 3.4s;
    }
  }

/* Define custom property */
@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}


 @keyframes moveLeftToRight {
  0% {
    background-position: 0 center;
  }
  100% {
    background-position: 20% center;
  }
}

.animate-move-bg {
  animation: moveLeftToRight 12s linear infinite;
  background-size: 180%;
}


[data-aos="new-left"] {
  opacity: 0;
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
  }

  @media screen and (min-width: 768px) {
    transform: translateX(20px);

    &.aos-animate {
      transform: translateY(0);
    }
  }
}
[data-aos="new-up"] {
  opacity: 0;
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
  }

  @media screen and (min-width: 768px) {
    transform: translateY(20px);

    &.aos-animate {
      transform: translateX(0);
    }
  }
}


[data-aos="new-right"] {
  opacity: 0;
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
  }

  @media screen and (min-width: 768px) {
    transform: translateX(-20px);

    &.aos-animate {
      transform: translateY(0);
    }
  }
}
[data-aos="left-right"] {
  opacity: 0;
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
  }

  @media screen and (min-width: 768px) {
    transform: translateX(-20px);

    &.aos-animate {
      transform: translateY(0);
    }
  }
}
[data-aos="discription"] {
  opacity: 0;
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
  }

  @media screen and (min-width: 768px) {
    transform: translateX(30px);

    &.aos-animate {
      transform: translateY(0);
    }
  }
}
@media screen and (min-width: 1350px) and (max-width: 1501px) {
  /* Your CSS rules for this range */
  .yellowLine {
    left: 17% !important;
   
  }
}


/* .zoom-out {
  animation: zoomOut 5s ease-in-out infinite alternate;
}
@keyframes zoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
} */

/* styles/globals.css or App.css */

/* Keyframes for the scale-up animation */
@keyframes bg-spin {
 from {
    --border-angle: 1turn;
  }
  to{
    --border-angle: 0.75turn;
  }
 
}


.bordered-element {
  border: none; 
  transition: border-color 0.3s ease; 
  background: conic-gradient(from var(--border-angle), #000, #000 5%, #000 60%, #000 95%) padding-box,
              conic-gradient(from var(--border-angle), transparent 83%, rgb(255, 255, 255) 84%, rgb(202, 138, 4) 92.3%, transparent 5%) border-box,
              conic-gradient(from var(--border-angle), #000, #000 5%, #000 60%, #000 95%) border-box;
  position: relative;
  background-position: center center;
  animation-fill-mode: forwards;
}

.animate-border {
  animation: bg-spin 7s linear  forwards;
}
/* .animate-border-reverse{
  animation: bg-spin 7s linear forwards;
  animation-direction: reverse;
} */

.bordered-element.final-state {
  border: 1px solid transparent;
  background: none;
}
.bordered-element-whoweare.animate-border-whoweare {
  border-top: solid 1px transparent;
  border-bottom: solid 1px transparent;
  border-right:solid 1px transparent ;
  background: conic-gradient(from var(--border-angle), #000, #000 5%, #000 60%, #000 95%) padding-box,
  conic-gradient(from var(--border-angle), transparent 83%, rgb(255, 255, 255) 84%, rgb(202, 138, 4) 92.3%, transparent 5%) border-box,
  conic-gradient(from var(--border-angle), #000, #000 5%, #000 60%, #000 95%) border-box;
position: relative;
background-position: center center;
animation-fill-mode: forwards;
}
.animate-border-whoweare {
  animation: bg-spin-whoweare 7s ease-out forwards;
}
@keyframes bg-spin-whoweare {
  0% {
    --border-angle: 0turn;
  
  }
  25% {
    --border-angle: 0.25turn;
  
  }
  50% {
    --border-angle: 0.5turn;
  
  }
  75% {
    --border-angle: 0.75turn;
  
  }
  100% {
    --border-angle: 0.85turn;
  
  }
}

.bordered-element.animate-border {
  border-left: solid 1px transparent;
 
  background: conic-gradient(from var(--border-angle), #000, #000 5%, #000 60%, #000 95%) padding-box,
  conic-gradient(from var(--border-angle), transparent 83%, rgb(255, 255, 255) 84%, rgb(202, 138, 4) 92%, transparent 5%) border-box,
  conic-gradient(from var(--border-angle), #000, #000 5%, #000 60%, #000 95%) border-box;
position: relative;
background-position: center center;
animation-fill-mode: forwards;
}
.bordered-element1.animate-border-vibecheck {
 
  border-bottom: solid 1px transparent;
  border-left:solid 1px transparent ;
  background: conic-gradient(from var(--border-angle), #000, #000 5%, #000 60%, #000 95%) padding-box,
  conic-gradient(from var(--border-angle), transparent 74%, rgb(255, 255, 255) 81%, rgb(202, 138, 4) 86%, transparent 5%) border-box,
  conic-gradient(from var(--border-angle), #000, #000 5%, #000 60%, #000 95%) border-box;
position: relative;
background-position: center center;
animation-fill-mode: forwards;
}
.animate-border-vibecheck{
  animation: bg-spin-vibecheck 7s linear forwards;
}
@keyframes  bg-spin-vibecheck {
  from {
    --border-angle: 1turn;
  }
  to {
    --border-angle: 0.5turn;
  }
  0% {
    --border-angle: 1turn;
  
  }
  25% {
    --border-angle: 0.75turn;
  
  }
  50% {
    --border-angle: 0.5turn;
  
  }
  75% {
    --border-angle: 0.25turn;
  
  }
  100% {
    --border-angle: 0turn;
  
  }
}
.bordered-element2.animate-border-vibecheck1 {
 
  border-bottom: solid 1px transparent;
  border-right:solid 1px transparent ;
  background: conic-gradient(from var(--border-angle), #000, #000 5%, #000 60%, #000 95%) padding-box,
  conic-gradient(from var(--border-angle), transparent 83%, rgb(255, 255, 255) 84%, rgb(202, 138, 4) 97%, transparent 5%) border-box,
  conic-gradient(from var(--border-angle), #000, #000 5%, #000 60%, #000 95%) border-box;
position: relative;
background-position: center center;
animation-fill-mode: forwards;
}
.animate-border-vibecheck1{
  animation: bg-spin-vibecheck1 7s linear forwards;
}
@keyframes  bg-spin-vibecheck1 {


  25% {
    --border-angle: 0.25turn;
  
  }
  50% {
    --border-angle: 0.5turn;
  
  }
  75% {
    --border-angle: 0.75turn;
  
  }
  100% {
    --border-angle: 1turn;
  
  }
}
.bordered-element3.animate-border-vibecheck {
 
  border-bottom: solid 1px transparent;
  border-left:solid 1px transparent ;
  background: conic-gradient(from var(--border-angle), #000, #000 5%, #000 60%, #000 95%) padding-box,
  conic-gradient(from var(--border-angle), transparent 81%, rgb(255, 255, 255) 84%, rgb(202, 138, 4) 92%, transparent 5%) border-box,
  conic-gradient(from var(--border-angle), #000, #000 5%, #000 60%, #000 95%) border-box;
position: relative;
background-position: center center;
animation-fill-mode: forwards;
}
.bordered-element4.animate-border-vibecheck1 {
 
  border-bottom: solid 1px transparent;
  border-right:solid 1px transparent ;
  background: conic-gradient(from var(--border-angle), #000, #000 5%, #000 60%, #000 95%) padding-box,
  conic-gradient(from var(--border-angle), transparent 83%, rgb(255, 255, 255) 84%, rgb(202, 138, 4) 97%, transparent 5%) border-box,
  conic-gradient(from var(--border-angle), #000, #000 5%, #000 60%, #000 95%) border-box;
position: relative;
background-position: center center;
animation-fill-mode: forwards;
}
  /* pulse */
  .fade-outs {
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }
  

  .fade-ins {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }

  