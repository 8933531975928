@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,400;0,700;1,400;1,700&display=swap');




.progress-line {
  height: 1px;
  background: #DDA556;
  animation: growLine 2s forwards;
  transform-origin: left;
  position: relative;
  z-index: 1;
}

@keyframes growLine {
  from {
    width: 0;
  }
  to {
    width: 50vw;
  }
}

/* Media Queries for different screen sizes */
@media (max-width: 640px) {
  @keyframes growLine {
    to {
      width: 70vw; /* Adjust width for small screens */
    }
  }
}
@media (max-width: 640px) {
  .pulse-data{
    display: none;
  }
}


@media (min-width: 641px) and (max-width: 1020px) {
  @keyframes growLine {
    to {
      width: 50vw; /* Adjust width for medium screens */
    }
  }
}

@media (min-width: 1023px) {
  @keyframes growLine {
    to {
      width: 40vw; /* Adjust width for large screens */
    }
  }
}
@media (min-width:1440px) {
  @keyframes growLine {
    to {
      width: 50vw; /* Adjust width for large screens */
    }
  }
}


.profile-info-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

.profile-image-container {
  position: relative;
  transition: opacity 3s ease-in-out, transform 3s ease-in-out;
}

.profile-image-container.exit {
  animation: fadeOut 5s backwards, zoomIn 5s backwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* .profile-image {
  width: 400px;
  height: 400px;
} */

.quote {
  font-family: 'Figtree';
  font-style: italic;
  margin-bottom: 10px;
  /* color: white; */
}

.name {
font-family: 'Figtree';
  color: #DDA556;
  font-weight: bold;
}



/* New CSS for image container and transition effects */
.image-container {
  position: relative;

  overflow: hidden;
}

.image-container img {
  position: absolute;
 
  transition: opacity 3s ease-in-out, transform 3s ease-in-out;
}

.image-container img.active {
  opacity: 1;
  transform: scale(1);
}

.image-container img.inactive {
  opacity: 0;
  transform: scale(0.8);
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-right {
  animation: fadeInRight 2s ease-out;
}
