/* Loading.css */
@keyframes zoomIns {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.1); /* Final scale */
      opacity: 0; /* Fade out */
    }
  }
  
  .zoom-animations {
    animation: zoomIns 1s ease-out forwards; /* Adjust duration to match the desired effect */
  }
  