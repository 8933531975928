/* Add this CSS to your stylesheet */
.carousel {
  overflow-y: auto;
  scroll-behavior: smooth;
  padding: 30px;
}

.carousel-item {
  transition: transform 0.5s ease, filter 0.3s ease;
}

.carousel-item.selected {
  transform: scale(1.5);
  filter: blur(0);
  position: relative;
  z-index: 60;
}

.carousel-item:not(.selected) {
  transform: scale(1);
  filter: blur(2px);
}

/* @keyframes zoom-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
} */
.zoom-out {
  transform: scale(0.8);
  transition: transform 3s ease-in-out; /* Adjust duration and easing as needed */
}


.animate-zoom-in {
  animation: zoom-in 0.5s ease-in-out;
}

.zoom-in {
  animation: zoomIn 1s forwards;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.loading-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  z-index: 9999;
  object-fit: cover;
}

.loading-video video {
  width: 100%;
  height: 100%;
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}

.zoom-out {
  animation: zoomOut 15s ease-in-out infinite alternate;
}
