.data-op>.hidden {
    opacity: 0;
  }
  
  .text-animation {
    opacity: 0;
    transform: translateY(5px); /* Start from 30px below */
    animation: fadeUp 1s forwards; /* Animation duration is 1 second */
  }
  .difference-animation {
    opacity: 0;
    transform: translateX(15px); /* Start from 5px to the right */
    animation: fadeInFromRight 1s forwards; /* Animation duration is 1 second */
  }
  
  @keyframes fadeInFromRight {
    to {
      opacity: 1;
      transform: translateX(0); /* Move to the original position */
    }
  }
  
  @keyframes fadeUp {
    to {
      opacity: 1;
      transform: translateY(0); /* Move to the original position */
    }
  }

  .letter-animation {
    opacity: 0;
    transform: translateX(-30px); /* Start from 30px to the left */
    animation: fadeInFromLeft 1s forwards; /* Animation duration is 1 second */
  }
  
  @keyframes fadeInFromLeft {
    to {
      opacity: 1;
      transform: translateX(0); /* Move to the original position */
    }
  }
  
  
  